export default {
  footer: state => state.app.footer,
  homeActive: state => state.app.homeActive,
  home: state => state.app.home,
  token: state => state.app.token,
  isLogin: state => !!state.app.token,
  backgroundColor: state => state.app.backgroundColor,
  userInfo: state => state.app.userInfo || {},
  storeid: state => state.app.storeid,
  latitude: state => state.app.latitude,
  longitude: state => state.app.longitude,
  addressName: state => state.app.addressName,
  unique: state => state.app.unique,//二维码编码
  ishotel: state => state.app.ishotel,//二维码类型（是否弹出换兌活动及弹出输入框）
};
